<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="Registrar Movimiento de Banco"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span for=""
            ><strong>TIPO MOVIMIENTO: </strong>
            <span class="p-invalid">*</span></span
          >
          <Dropdown
            autofocus
            v-model="tipo_mov"
            :options="tp_movimientos"
            optionLabel="label"
            placeholder="Seleccione tipo Movimiento"
          />
          <small class="p-invalid" v-if="errors.tipo_mov">{{
            errors.tipo_mov[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span for="metodo_pago"
            ><strong>M&Eacute;TODO DE PAGO:</strong>
            <small class="p-invalid">*</small></span
          >
          <Dropdown
            id="metodo_pago"
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-6">
          <span for=""><strong>MONTO:</strong> </span>
          <InputNumber
            v-model="monto_efectivo"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.monto_efectivo">{{
            errors.monto_efectivo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6" v-if="metodo_pagoSelect != 1">
          <span for=""><strong>QR/TRANSFERENCIAS(+):</strong></span>
          <InputNumber
            v-model="monto_transferencia"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.monto_transferencia">{{
            errors.monto_transferencia[0]
          }}</small>
        </div>
        <div class="field col-12">
          <span for=""
            ><strong>CONCEPTO: </strong> <span class="p-invalid">*</span></span
          >
          <Textarea
            v-model="data_mov_banco.concepto"
            rows="1"
            autoResize
            placeholder="Ingrese Concepto"
          />
          <small class="p-invalid" v-if="errors.concepto">{{
            errors.concepto[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarMovBanco"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import BancoService from "@/service/BancoService";
import MetodoPagoService from "@/service/MetodoPagoService.js";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mov_banco: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_mov_banco: this.mov_banco,
      enviado: false,
      errors: {},
      tipo_mov: { label: "EGRESO", value: 0 },
      tp_movimientos: [
        { label: "INGRESO", value: 1 },
        { label: "EGRESO", value: 0 },
      ],
      caja_id: null,
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      monto_efectivo: null,
      monto_transferencia: null,
    };
  },
  bancoService: null,
  metodoPagoService: null,
  created() {
    this.bancoService = new BancoService();
    this.metodoPagoService = new MetodoPagoService();
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    validacionesForm() {
      this.errors = {};

      if (
        this.metodo_pagoSelect == 1 &&
        (!this.monto_efectivo || this.monto_efectivo == 0)
      ) {
        this.errors.monto_efectivo = ["El campo Monto es requerido"];
      }

      if (
        this.metodo_pagoSelect != 1 &&
        (!this.monto_transferencia || this.monto_transferencia == 0)
      ) {
        this.errors.monto_transferencia = ["El campo Monto es requerido"];
      }

      if (!this.data_mov_banco.concepto) {
        this.errors.concepto = ["El campo Concepto es requerido"];
      }

      return Object.keys(this.errors).length == 0;
    },
    guardarMovBanco() {
      this.errors = {};
      if (!this.validacionesForm()) {
        return false;
      }
      this.enviado = true;
      let datos = {
        ...this.data_mov_banco,
        tipo_mov: this.tipo_mov.value,
        metodo_pago_id: this.metodo_pagoSelect,
        monto_efectivo: this.monto_efectivo ? this.monto_efectivo : 0,
        monto_transferencia: this.monto_transferencia
          ? this.monto_transferencia
          : 0,
      };
      this.bancoService.nuevoMovBanco(datos).then((data) => {
        if (Object.defineProperty.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          this.enviado = false;
          this.errors = {};
          if (data.success == false) {
            this.enviado = false;
            this.$toast.add({
              severity: "error",
              summary: "Movimiento de Banco",
              detail: data.message,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Movimiento de Banco",
              detail: data.message,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarModal();
            this.enviado = false;
          }
        }
      });
    },
    ocultarModal() {
      this.enviado = false;
      this.errors = {};
      this.$emit("closeModal");
      this.metodo_pagoSelect = 1;
      this.monto_efectivo = null;
      this.monto_transferencia = null;
      this.tipo_mov = { label: "EGRESO", value: 0 };
      this.concepto = "";
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    mov_banco(value) {
      this.data_mov_banco = value;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
